//// Mobile menu
const mobileNavTab = document.getElementById('nav-toggle-tab');
const nav = document.getElementById('main-nav-bar');
const taxonBar = document.getElementById('taxon-bar');

mobileNavTab.addEventListener('click', () => {
  nav.classList.toggle('hidden');
  taxonBar.classList.toggle('hidden');
});

//// Sticky nav on scroll
const header = document.getElementById('header');
const shopNav = document.getElementById('taxonomies');
const taxonTopNav = document.getElementById('taxon-top-nav');
const taxonLogo = document.getElementById('taxon-logo');
const taxonRoots = document.querySelectorAll('.taxonomy-root');
const shopLinkContainer = document.getElementById('shop-link-container');
const linkToCart = document.getElementById('link-to-cart');
const userNav = document.getElementById('user-nav');
const spacer = document.getElementById('taxon-nav-left-spacer');

const stickyNav = function (entries) {
  const [entry] = entries;
  const taxonBarClasses = ['top-0', 'left-0', 'fixed', 'bg-white', 'shadow', 'w-full', 'content-center', 'items-center', 'justify-center']
  
  if (!entry.isIntersecting) {
    // Class Modifiers
    taxonBar.classList.remove('relative')
    taxonBar.classList.add(...taxonBarClasses);
    taxonLogo.classList.remove('hidden');
    taxonLogo.classList.add('flex');
    spacer.classList.add('hidden');
    [taxonLogo, shopLinkContainer, ...taxonRoots].forEach((el) => { el.classList.add('md:py-4') });
    // Clone user nav
    node = userNav.cloneNode(true);
    shopNav.appendChild(node);
    newEl = shopNav.lastChild;
    newEl.id = `${userNav.id}-clone`;
    newEl.removeAttribute('class');
    newEl.classList.add('py-4', 'mx-2', 'flex', 'items-center', 'justify-center');
    const localeSelector = newEl.querySelector('.locale-selector');
    localeSelector.classList.add('hidden');
    localeSelector.classList.remove('sm:inline');
  } else {
    taxonBar.classList.remove(...taxonBarClasses);
    taxonLogo.classList.remove('flex');
    taxonLogo.classList.add('hidden');
    spacer.classList.remove('hidden');
    [taxonLogo, shopLinkContainer, ...taxonRoots].forEach((el) => {
      el.classList.remove('md:py-4');
    });
    const clone = document.getElementById(`${userNav.id}-clone`);
    if (clone) clone.remove();
  }
};
// sticky nav init
const headerObserver = new IntersectionObserver(stickyNav, {
  root: null,
  threshold: 0,
  rootMargin: `-${taxonBar.offsetHeight + 1}px`,
});
headerObserver.observe(header);

// Missing Translation Console Log
const missingTranslations = document.querySelectorAll('span.translation_missing');

if (missingTranslations.length > 0) {
  console.log(`${missingTranslations.length} Missing Translations:`);
  missingTranslations.forEach((el) => {
    console.log(el.title);
  });
}